<template>
	<div class="">
		<el-dialog
			v-loading="listLoading"
			:visible.sync="isActiveDialog"
			:width="width+'px'"
			:style="cssVars"
			inline
			:before-close="handleCancel">
			<span slot="title" style="font-size: 14px;color: #606266;font-weight: 700;">代煎中药审核</span>
			
			
			<el-image
				style="width: 600px; height: 720px"
				:src="this.info && this.info.pres_pic"
				fit="fill"></el-image>
			
			<!-- <div style="width: 260px">
			<div class="search-container-fn-input">
				<label>加工类型：</label>
				<el-select
					:disabled="type == 'see'"
					style="width:200px"
					v-model="processType"
					placeholder="审核状态"
					size="small"
					clearable
				>
					<template slot="prefix">
						<i class="el-icon-search" style="width: 25px; line-height: 32px;"></i>
					</template>
					<el-option
						v-for="item in processTypeOption"
						:key="item.value"
						:label="item.label"
						:value="item.value">
					</el-option> 
				</el-select>
			</div>
			</div> -->
			<div style="margin-top: 10px"></div>
			<div slot="footer" class="dialog-footer">
				<el-button class="mgr-20" size="mini" @click="handleCancel">取 消</el-button>
				<el-button size="mini" type="primary" @click="handleConfirm('ruleForm')">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {postDrugAgentExam} from '@/api/audit/drugAgentAudit'

export default {
  name: "DialogDrugAgentAudit",
  props: {
    width: { // 弹出框的宽度配置
      type: String,
			default: '640',
		},
    isActiveDialog: {
      type: Boolean,
      default: false
    },
		info: {
      type: Object,
			default: () => {}
		},
    type: {
      type: String,
			default: ''
    }
  },
	computed: {
    cssVars() {
      return {
        "--widthToOne" : this.width - 50 + "px",
        "--widthToTwo" : this.width / 2 - 40+ "px",
			}
		}
	},
  data () {
    return {
      listLoading: false,
      url: [],
      options: [{
        value: 'test',
        id: '1'
      }],
      processType: '',
    //   processTypeOption: [
    //     {
    //       value: '1',
    //       label: '汤药'
    //     },
    //     {
    //       value: '2',
    //       label: '丸剂'
    //     },
    //     {
    //       value: '3',
    //       label: '打粉'
    //     },
    //     {
    //       value: '4',
    //       label: '膏方代配'
    //     },
    //     {
    //       value: '5',
    //       label: '膏方'
    //     },
    //   ],
    }
  },
  methods: {
    handleSelect () {

    },
    handleConfirm (ruleForm) {
      this.submitForm(ruleForm)
    },
    handleCancel (e) {
      this.$emit('update:isActiveDialog', false)
    },
    submitForm (formName) {
    //   if (this.processType.length == 0) {
    //     this.$message({
	// 				type: 'warning',
	// 				message: '选择加工类型'
	// 			})
	// 		} else {
        var data = {
          examine_status: '1'
				}
        var id = this.info.order_id
        postDrugAgentExam(data, id).then(res => {
          console.log(res)
					if(res.code == 200) {
					  this.$message({
							type: 'success',
							message: '审核成功'
						})
						this.$emit('getList')
            this.$emit('update:isActiveDialog', false)
					} else{
            this.$message({
              type: 'error',
              message: res.msg
            })
					}
				}).catch(err => {
          console.log(err)
				})
				
    
			}

    // },
  }
}
</script>

<style scoped lang="scss">
	/*dialog对话框样式调整*/
	::v-deep .el-dialog__body {
		padding-bottom: 0 !important;
	}
	
	::v-deep .el-dialog__footer {
		background-color: #fafafa;
		border-top: 1px solid #eaeaea;
		padding: 10px 30px 10px 0;
	}
	
	/*dialog对话框样式调整*/
	::v-deep .el-dialog__body {
		padding-bottom: 0 !important;
	}
	
	::v-deep .el-dialog__footer {
		background-color: #fafafa;
		border-top: 1px solid #eaeaea;
		padding: 10px 30px 10px 0;
	}
	
	
	/*弹出框表单配置*/
	::v-deep .el-form-item__label {
		padding-bottom: 0;
	}
	
	::v-deep .el-form-item__content {
		width: var(--widthToTwo);
	}
	
	.from-item-second {
		margin-left: 0;
	}
	
	::v-deep .from-item-third {
		.el-form-item__content {
			width: var(--widthToOne);
		}
		.el-select{
			width: var(--widthToOne) !important;
		}
	}
	
	::v-deep .el-cascader {
		width: var(--widthToTwo);
	}
	
	::v-deep .el-select {
		width: var(--widthToTwo) !important;
	}
	
	::v-deep .el-date-editor {
		width: var(--widthToTwo) !important;
	}
	
	/*select 宽度配置*/
	::v-deep .el-select {
		width: 85px;
	}
</style>
