<template>
  <div class="">
    <el-dialog
      v-loading="listLoading"
      :visible.sync="isActiveDialogOrderExpress"
      :width="width + 'px'"
      :style="cssVars"
      inline
      :before-close="handleCancel"
    >
      <span
        slot="title"
        style="font-size: 14px;color: #606266;font-weight: 700;"
        >订单编号：{{ infoOrder.order_sn }}</span
      >

      <el-form
        :model="ruleForm"
        :rules="rules"
        size="mini"
        :inline="true"
        label-position="top"
        ref="ruleForm"
      >
        <el-form-item
          label="快递公司"
          prop="express_company"
          class="from-item-third"
        >
          <el-select
            style="width:200px"
            v-model="ruleForm.express_company"
            placeholder="请选择"
            size="mini"
            clearable
          >
            <template slot="prefix">
              <i
                class="el-icon-search"
                style="width: 25px; line-height: 32px;"
              ></i>
            </template>
            <el-option
              v-for="item in express_company_list"
              :key="item.key"
              :label="item.label"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          v-if="ruleForm.express_company != '自主配送'"
          label="快递单号"
          prop="express_no"
          class="from-item-third"
        >
          <el-input
            v-model.trim="ruleForm.express_no"
            autocomplete="off"
            clearable
            placeholder="订单编号"
          ></el-input>
        </el-form-item>

        <el-form-item
          v-if="infoOrder.order_type == 5"
          label="下单药房"
          prop="placing_pharmacy"
          class="from-item-third"
        >
          <el-select
            style="width:200px"
            v-model="ruleForm.placing_pharmacy"
            placeholder="请选择"
            size="mini"
            clearable
          >
            <template slot="prefix">
              <i
                class="el-icon-search"
                style="width: 25px; line-height: 32px;"
              ></i>
            </template>
            <el-option
              v-for="item in this.placing_pharmacy"
              :key="item.id"
              :label="item.pharmacy_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button class="mgr-20" size="mini" @click="handleCancel"
          >取 消</el-button
        >
        <el-button size="mini" type="primary" @click="handleConfirm('ruleForm')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { postSendOrder } from "@/api/audit/drugAgentAudit.js";
export default {
  name: "DialogOrderExpress",
  props: {
    width: {
      // 弹出框的宽度配置
      type: String,
      default: "640",
    },
    isActiveDialogOrderExpress: {
      type: Boolean,
      default: false,
    },
    infoOrder: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    cssVars() {
      return {
        "--widthToOne": this.width - 50 + "px",
        "--widthToTwo": this.width / 2 - 40 + "px",
      };
    },
  },
  data() {
    var curOrderExpress = this.infoOrder;
    return {
      listLoading: false,
      bills: [], // 快递信息
      curOrderExpress, // 当前发货订单
      express_company_list: [
        {
          key: "自主配送",
          label: "自主配送",
        },
        {
          key: "顺丰快递",
          label: "顺丰快递",
        },
        {
          key: "中通快递",
          label: "中通快递",
        },
        {
          key: "圆通快递",
          label: "圆通快递",
        },
        {
          key: "韵达快递",
          label: "韵达快递",
        },
        {
          key: "申通快递",
          label: "申通快递",
        },
        {
          key: "百世快递",
          label: "百世快递",
        },
        {
          key: "京东快递",
          label: "京东快递",
        },
        {
          key: "邮政小包",
          label: "邮政小包",
        },
        {
          key: "邮政EMS",
          label: "邮政EMS",
        },
        {
          key: "德邦物流",
          label: "德邦物流",
        },
      ],
      //下单药房
      placing_pharmacy: this.placing_pharmacy,
      ruleForm: {
        express_company: "",
        express_no: "",
        placing_pharmacy: "",
      },
      rules: {
        express_company: [
          { required: true, message: "快递公司", trigger: "blur" },
        ],
        express_no: [{ required: true, message: "订单编号", trigger: "blur" }],
        placing_pharmacy: [
          { required: true, message: "下单药房", trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    "ruleForm.express_company": function(val) {
      if (val == "自主配送") {
        this.ruleForm.express_type = 1;
        console.log(this.ruleForm, "1234214=======自主配送");
      } else {
        this.ruleForm.express_type = 2;
        console.log(this.ruleForm, "1234214=======非自主配送");
      }
    },
  },
  methods: {
    async postSendOrder(data, id) {
      this.listLoading = true;
      try {
        var response = await postSendOrder(data, id);
        if (response.code == 200) {
          this.$message({
            type: "success",
            message: "成功",
          });
        } else {
          this.$message({
            type: "error",
            message: response.msg,
          });
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.listLoading = false;
      }
    },
    handleConfirm(ruleForm) {
      this.submitForm(ruleForm);
    },
    handleCancel(e) {
      this.resetForm("ruleForm");
      this.$emit("update:isActiveDialogOrderExpress", false);
    },
    submitForm(formName) {
      console.log(this.$refs[formName]);
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // 出发test页面更新列表
          var data = {
            bills: [this.ruleForm],
          };
          await this.postSendOrder(this.ruleForm, this.infoOrder.order_id);
          this.$emit("getList");
          this.$emit("update:isActiveDialogOrderExpress", false);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped lang="scss">
/*dialog对话框样式调整*/
::v-deep .el-dialog__body {
  padding-bottom: 0 !important;
}

::v-deep .el-dialog__footer {
  background-color: #fafafa;
  border-top: 1px solid #eaeaea;
  padding: 10px 30px 10px 0;
}

/*dialog对话框样式调整*/
::v-deep .el-dialog__body {
  padding-bottom: 0 !important;
}

::v-deep .el-dialog__footer {
  background-color: #fafafa;
  border-top: 1px solid #eaeaea;
  padding: 10px 30px 10px 0;
}

/*弹出框表单配置*/
::v-deep .el-form-item__label {
  padding-bottom: 0;
}

::v-deep .el-form-item__content {
  width: var(--widthToTwo);
}

.from-item-second {
  margin-left: 0;
}

::v-deep .from-item-third {
  .el-form-item__content {
    width: var(--widthToOne);
  }
  .el-select {
    width: var(--widthToOne) !important;
  }
}

::v-deep .el-cascader {
  width: var(--widthToTwo);
}

::v-deep .el-select {
  width: var(--widthToTwo) !important;
}

::v-deep .el-date-editor {
  width: var(--widthToTwo) !important;
}

/*select 宽度配置*/
::v-deep .el-select {
  width: 85px;
}
</style>
