<template>
  <div class=".gg-container-1">
    <div class="search-container">
      <!--输入框-->
      <div class="search-container-fn-input">
        <label>处方编码：</label>
        <el-input
          style="width: 200px"
          size="small"
          placeholder="处方编号"
          prefix-icon="el-icon-search"
          clearable
          @change="reset"
          v-model="searchParams.pres_sn"
        >
        </el-input>
      </div>
	
			<el-button
				size="mini"
				type="primary"
				style="padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
				@click="reset"
			>
				<span>查找</span>
			</el-button>
      <div
        style="margin-left: auto; display: flex; justify-content: space-around"
      >
        <div class="search-container-fn-input">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
            @click="handleAudit('audit')"
            v-if="
              operateList.indexOf('examine') > -1 &&
                multipleSelectionPres.length == 1 &&
                multipleSelectionPres[0].order_status == 2
            "
            >审核
          </el-button>
        </div>
        <div class="search-container-fn-input">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
            @click="handleSee(multipleSelectionPres[0], 'see')"
            v-if="multipleSelectionPres.length == 1"
            >查看
          </el-button>
        </div>
        <div class="search-container-fn-input">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
            @click="handleLogisticsSee('see')"
            v-if="
              operateList.indexOf('express') > -1 &&
                multipleSelectionPres.length == 1 &&
                (multipleSelectionPres[0].order_status == 4 ||
                  multipleSelectionPres[0].order_status == 3)
            "
            >查看物流
          </el-button>
        </div>
        <div class="search-container-fn-input">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
            @click="handleRefund('see')"
            v-if="
              operateList.indexOf('refund') > -1 &&
                multipleSelectionPres.length == 1 &&
                (multipleSelectionPres[0].order_status == 2 ||
                  multipleSelectionPres[0].order_status == 3 || 
                  multipleSelectionPres[0].order_status == 4
                  )
            "
            >退款
          </el-button>
        </div>
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('export') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit-outline"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
            @click="exportData"
            >导出
          </el-button>
        </div>
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('update_table') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit-outline"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
            @click="handleEditTableHead"
            >编辑表头
          </el-button>
        </div>
        <div
          class="search-container-fn-input"
          v-if="operateList.indexOf('reset_query') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
            @click="handleResetSearch"
            >重置查找
          </el-button>
        </div>
        <!--对应第三方图标的button样式-->
      </div>
    </div>
    <!----------------表格---------------->
    <el-table
      border
      size="mini"
      v-loading="listLoading"
      :header-cell-style="{ 'text-align': 'center' }"
      :data="tableData"
      height="725"
      @selection-change="handleSelectionChange"
      :row-class-name="tableRowClassName"
      style="width: 100%;z-index:0"
    >
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column type="index" align="center" width="50">
      </el-table-column>
      <template v-for="(column, index) in tableHead">
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-if="column.field_type === 'textBtn' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a
              style="font-size: 12px; color: #2379fb"
              @click.prevent="handleSee(scope.row, 'see')"
            >
              {{ scope.row[column.column_prop] }}
            </a>
          </template>
        </el-table-column>
        <el-table-column
          :sortable="tableHeadSortable.includes(column.column_prop)"
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <a v-if="column.column_label_user_definition == '订单状态'">
              {{ scope.row[column.column_prop] | formateOrderStatus }}
            </a>
            <a v-else-if="column.column_label_user_definition == '审核状态'">
              {{ scope.row[column.column_prop] == 2 ? "已审核" : "待审核" }}
            </a>
            <a
              v-else-if="column.column_label_user_definition == '医生是否双签'"
            >
              {{ scope.row[column.column_prop] == 1 ? "是" : "无需双签" }}
            </a>
            <a v-else>
              {{ scope.row[column.column_prop] }}
            </a>
          </template>
          <template #header>
            <el-popover
              placement="bottom"
              title=""
              min-width="160"
              trigger="click"
              v-if="
                searchTableHead.filter(
                  (item) => item.name == column.column_prop
                ).length > 0
              "
            >
              <span slot="reference" class="search-header">
                <span class="search-title">{{
                  column.column_label_user_definition
                    ? column.column_label_user_definition
                    : column.column_label
                }}</span>
                <i
                  style="margin-left: 3px;font-weight: bolder; font-size: 20px"
                  class="el-icon-search"
                  :class="{
                    isSearch: searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                    'el-icon-zoom-in': searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                  }"
                />
              </span>
              <el-select
                v-if="'examine_status' == column.column_prop"
                style="width: 200px"
                @change="
                  handleSearch(column.column_prop, searchParams.examine_status)
                "
                v-model="searchParams.examine_status"
                clearable
                placeholder="请选择"
                size="small"
              >
                <template v-for="item in options">
                  <el-option
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </template>
              </el-select>
              <el-select
                v-if="'order_status' == column.column_prop"
                style="width: 200px"
                @change="
                  handleSearch(column.column_prop, searchParams.order_status)
                "
                v-model="searchParams.order_status"
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option
                  v-for="item in options1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <el-input
                v-if="'patient_name' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.patient_name"
                clearable
                @change="
                  handleSearch(column.column_prop, searchParams.patient_name)
                "
              >
              </el-input>
              <el-input
                v-if="'doctor_name' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.doctor_name"
                clearable
                @change="
                  handleSearch(column.column_prop, searchParams.doctor_name)
                "
              >
              </el-input>
              <el-input
                v-if="'pres_sn' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.pres_sn"
                clearable
                @change="handleSearch(column.column_prop, searchParams.pres_sn)"
              >
              </el-input>
            </el-popover>
            <span v-else>{{
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>

    <!----------------分页---------------->
    <!--<pagination :total="total" :page.sync="listQuery.page" -->
    <!--						:limit.sync="listQuery.limit"-->
    <!--						@pagination="getList"/>-->

    <pagination
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <!--编辑表头-->
    <editTableHead
      :isActiveEditTableHead.sync="isActiveEditTableHead"
      @_getAdminFieldIndex="_getAdminFieldIndex"
      v-if="isActiveEditTableHead"
      @getList="getList"
      :table_type="table_type"
    >
    </editTableHead>
    <!--弹出会话框-->
    <!--传入宽度改变弹出框大小-->
    <dialogDrugAgentAudit
      :isActiveDialog.sync="isActiveDialog"
      @getList="getList"
      :info="info"
      :type="type"
      v-if="isActiveDialog"
      width="640"
    ></dialogDrugAgentAudit>

    <!--物流弹出框-->
    <el-dialog
      :visible.sync="dialogLogisticsVisible"
      width="640"
      v-loading="listLoading"
      :before-close="handleLogisticsClose"
    >
      <span
        slot="title"
        style="font-size: 14px;color: #606266;font-weight: 700;"
        >物流信息</span
      >
      <div style="display: flex; margin-bottom: 20px">
        <div style="margin-right: 20px">{{ expressName }}</div>
        <div style="margin-top: 2px">{{ expressCode }}</div>
      </div>

      <el-timeline>
        <el-timeline-item
          v-for="(activity, index) in activities"
          :key="index"
          :timestamp="activity.timestamp"
        >
          {{ activity.content }}
        </el-timeline-item>
      </el-timeline>
      <div slot="footer" class="dialog-footer">
        <el-button class="mgr-20" size="mini" @click="handleLogisticsClose"
          >取 消</el-button
        >
      </div>
    </el-dialog>
    <dialogOrderExpress
      :isActiveDialogOrderExpress.sync="isActiveDialogOrderExpress"
      :infoOrder="infoOrder"
      @getList="getList"
      v-if="isActiveDialogOrderExpress"
      width="640"
      originOrder="customer"
    ></dialogOrderExpress>
    <el-dialog
      title="退款"
      :visible.sync="flgRefund"
      width="30%"
      :before-close="handleCloseRefund"
    >
      <div>订单原始金额:{{ actual_amount }}</div>
      <el-input
        v-model="inputMount"
        placeholder="注:输入金额不能大于订单金额"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="flgRefund = false">取 消</el-button>
        <el-button type="primary" @click="handleFlgRefund()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 消息提示音-->
    <audio controls="controls" hidden src="@/audio/sms.mp3" ref="audio"></audio>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import dialogDrugAgentAudit from "./components/dialogDrugAgentAudit";
import dialogOrderExpress from "./components/dialogOrderExpress";
import {
  getDrugAgentList,
  getOrderExpress,
  exportDrugAgentList,
  refound,
} from "@/api/audit/drugAgentAudit";
import { downloadFile } from "@/utils/common";
import { getAdminFieldIndex } from "@/api/drugs2.0";
import editTableHead from "@/components/editTableHead/editTableHead";
import { mapState } from "vuex";
import exportSelectTable from "@/components/exportSelectTable/exportSelectTable.vue";
import {exportPres} from "@/api/informationSearch";

const orderList = [
  {
    value: 1,
    label: "待支付",
  },
  {
    value: 2,
    label: "待发货",
  },
  {
    value: 3,
    label: "待收货",
  },
  {
    value: 4,
    label: "已完成",
  },
  {
    value: 5,
    label: "退回",
  },
];
export default {
  // 在用客户
  name: "drugAgentAudit",
  components: {
    Pagination,
    // eslint-disable-next-line vue/no-unused-components
    editTableHead,
    // eslint-disable-next-line vue/no-unused-components
    dialogDrugAgentAudit,
    // eslint-disable-next-line vue/no-unused-components
    dialogOrderExpress,
  },
  computed: {
    ...mapState({
      table_options: (state) => state.user.table_options,
    })
  },

  async created() {
    console.log(11111111111, this.$store.state.user);
    await this._getAdminFieldIndex();
    this.getList();
    // if (!this.$store.state.websocket.socket) {
    //   let wsUrl;
    //   if (
    //     process.env.VUE_APP_BASE_API &&
    //     process.env.VUE_APP_BASE_API.includes("ihis-test-api-admin")
    //   ) {
    //     wsUrl = "ws://ihis-test-api-admin.gegejk.com/wss";
    //   } else {
    //     wsUrl = "wss://admin-hospital.hngegejk.cn/wss";
    //   }
    //   console.log("====开始建立websocket链接====");
    //   this.$store.dispatch("websocket/connect", wsUrl);
    // }
  },
  watch: {
    // "$store.state.websocket.messageList": {
    //   handler: function() {
    //     let message = this.$store.state.websocket.messageList;
    //     let lastMsg = message.slice(-1)[0];
    //     console.log("=====接受websocket===", lastMsg);
    //     if (lastMsg.type == "message") {
    //       let data = JSON.parse(lastMsg.data);
    //       data.forEach((item) => {
    //         this.tableData.unshift(item);
    //         this.tableData.splice(-1);
    //       });
    //       console.log(123, data);
    //       this.$notify({
    //         title: "提示",
    //         message: lastMsg.message,
    //         duration: 0,
    //       });
    //       this.$refs.audio.currentTime = 0; // 从头开始播放提示音
    //       this.$refs.audio.play(); // 播放
    //       // this.say2( msgData.content);
    //     }
    //   },
    // },
  },
  filters: {
    //格式化订单状态
    formateOrderStatus(orderStatus) {
      let status = "";
      orderList.forEach((item, index) => {
        if (orderStatus == item.value) status = item.label;
      });

      return status;
    },
  },
  data() {
    return {
      table_type: 'drugAgentAudit',
      isActiveSelectTable: false,
      views_type: "drugAgentAudit",
      operateList: [],
      multipleSelectionPres: [],
      multipleSelection: [],
      options: [
        {
          value: 2,
          label: "已审核",
        },
        {
          value: 0,
          label: "待审核",
        },
      ],
      options1: orderList,
      flgRefund: false, //退款状态
      inputMount: "", //退款金额回显
      isActiveDialogOrderExpress: false,
      activities: [],
      expressCode: "",
      expressName: "",
      dialogLogisticsVisible: false,
      actual_amount: "", //原始金额
      tableHead: [],
      isActiveEditTableHead: false,
      searchTableHead: [
        //控制搜索
        {
          name: "patient_name",
          isSearch: false,
        },
        {
          name: "doctor_name",
          isSearch: false,
        },
        {
          name: "pres_sn",
          isSearch: false,
        },
        {
          name: "order_status",
          isSearch: false,
        },
        {
          name: "examine_status",
          isSearch: false,
        },
      ],
      tableHeadSortable: [
        //控制排序
        "created_at",
        "expired_at",
        "next_visit_at",
        "end_visit_at",
        "end_order_at",
      ],
      info: {},
      type: 1, //1--医师,2--药师
      dialogTableVisible: false,
      searchParams: {
        orderTime: [],
      },
      total: 0,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 100,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      tableData: [],
      isActiveDialog: false,
      infoOrder: {
        a: 1,
      },
      order_id: "",
    };
  },
  mounted() {
    this.$store.dispatch("user/getOperates", this.$route.path).then((res) => {
      this.operateList = res;
    });
  },
  methods: {
    handleCloseRefund() {
      console.log(1111111111);
      this.flgRefund = false;
      this.inputMount = "";
    },
    //退款接口
    handleFlgRefund() {
      console.log(this.inputMount);
      let data = {
        refund_amount: this.inputMount,
      };
      refound(data, this.order_id)
        .then((res) => {
          if (res.code == 200) {
            console.log(res);
            this.$message.success("退款成功");
            this.flgRefund = false;
            this.inputMount = "";
            this.getList();
          } else {
            this.$message.error(res.msg);
            this.flgRefund = false;
            this.inputMount = "";
            this.getList();
          }
        })
        .catch((err) => {
          console.log(err);
          this.getList();
        });
    },
    //查看
    handleSee(row, type) {
      let data = row;
      this.info = data;
      this.isActiveDialog = true;
      this.type = type;
    },
    //退款
    handleRefund() {
      let row = this.multipleSelectionPres[0];
      (this.actual_amount = row.actual_amount), (this.order_id = row.order_id);
      this.flgRefund = true;
    },
    //查看物流
    handleLogisticsSee() {
      let row = this.multipleSelectionPres[0];
      this.activities = [];
      this.expressName = "";
      this.expressCode = "";
      this.dialogLogisticsVisible = true;
      var params = {
        order_id: row.order_id,
      };
      getOrderExpress(params)
        .then((res) => {
          this.listLoading = true;
          console.log(res);
          if (res.code == 200) {
            this.expressName = res.data.expName;
            this.expressCode = res.data.number;
            var tmp = [];
            res.data.list.forEach((item) => {
              var temp = {};
              temp.content = item.status;
              temp.timestamp = item.time;
              tmp.push(temp);
            });
            console.log(tmp);
            this.activities = tmp;
          } else {
            this.$message({
              type: "error",
              message: res.msg,
            });
          }
          this.listLoading = false;
        })
        .catch((e) => (this.listLoading = false));
    },
    //审核
    handleAudit(type) {
      let row = this.multipleSelectionPres[0];
      if (row.type == 1) {
        this.info = row;
        this.isActiveDialog = true;
        this.type = type;
      } else {
        this.infoOrder = row;
        this.isActiveDialogOrderExpress = true;
      }
    },
    //导出数据
    exportData() {
      this.listLoading = true;
      let params = Object.assign({}, this.searchParams);
      console.log(params);
      exportDrugAgentList(params).then((res) => {
        downloadFile(res, "中药代煎审核.xlsx");
      });
      this.listLoading = false;

      //exportDrugAgentList
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.examine_status == 2) {
        return "warning-row";
      }
      return "success-row";
    },
    handleLogisticsClose() {
      this.dialogLogisticsVisible = false;
    },
    reset() {
      // this.searchParams = {};
      this.listQuery.page = 1;
      this.getList();
    },
    getList(type) {
      console.log("getList触发");
      if (type == "restPage") {
        this.listQuery.page = 1;
      }
      console.log("getList触发");
      this.listLoading = true;
      this.searchParams.page = this.listQuery.page;
      this.searchParams.page_size = this.listQuery.limit;
      getDrugAgentList(this.searchParams)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            console.log(1111111111111, data);
            let _data = data.data;
            this.total = _data.total;
            this.tableData = _data.list;
          }
          this.listLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.listLoading = false;
        });
    }, // 如果draw数据更新,回调getList()刷新数据

    handleSearch(prop, value) {
      console.log(prop, value, "2312343");
      this.searchTableHead.forEach((item) => {
        if (item.name == prop) {
          if (value == "") {
            item.isSearch = false;
          } else {
            item.isSearch = true;
          }
        }
      });
      this.getList("restPage");
    },
    handleResetSearch() {
      this.searchParams = {
        is_wx: "",
        visit_level: "",
        buy_count: "",
      };
      this.searchTableHead.forEach((item) => {
        item.isSearch = false;
      });
      this.choiceDateCreateRecord = [];
      this.choiceDateExpire = [];
      this.choiceDateEndVisit = [];
      this.choiceDateEndOrder = [];
      this.choiceDateNextVisit = [];
      this.getList("restPage");
    },
    handleEditTableHead() {
      this.isActiveEditTableHead = true;
    },
    async _getAdminFieldIndex() {
      // try {
      //   this.listLoading = true;
      //   var params = {
      //     type: "drugAgentAudit",
      //   };
      //   const res = await getAdminFieldIndex(params);
        this.tableHead =this.table_options.drugAgentAudit;
      //   console.log(this.tableHead);
      // } catch (err) {
      //   //在此处理错误
      // } finally {
      //   this.listLoading = false;
      // }
    },

    handleSelectionChange(val) {
      console.log(val, "val");
      this.multipleSelection = [];
      this.multipleSelectionPres = [];
      val.forEach((item) => {
        this.multipleSelection.push(item.id);
        this.multipleSelectionPres.push(item);
      });
    },
    handleCustomerDetail(row, e) {
      console.log(row, e);
    },
  },
};
</script>

<style lang="scss" scoped>
.isSearch {
  color: #f56c6c;
}

.search-icon {
  color: #f56c6c;
}

/* switch按钮样式 */
::v-deep.switch {
  .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }

  /*打开时文字位置设置*/
  .el-switch__label--right {
    z-index: 1;
  }

  /* 调整打开时文字的显示位子 */
  .el-switch__label--right span {
    margin-right: 11px;
  }

  /*关闭时文字位置设置*/
  .el-switch__label--left {
    z-index: 1;
  }

  /* 调整关闭时文字的显示位子 */
  .el-switch__label--left span {
    margin-left: 11px;
  }

  /*显示文字*/
  .el-switch__label.is-active {
    display: block;
  }

  /* 调整按钮的宽度 */
  .el-switch__core,
  .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
}

/*抽屉高度*/
::v-deep .el-drawer {
  bottom: 0 !important;
}

::v-deep .el-drawer__header {
  padding: 0;
  margin: 0;
}

::v-deep .el-drawer.rtl {
  overflow: visible;
  height: 100vh;
  bottom: 0;
  margin-top: 0vh;
  position: absolute;
}
::v-deep .el-table .warning-row {
  color: red;
}
</style>
